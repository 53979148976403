<template>
  <div>
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="@/assets/skl-logo.png"
          alt="Smiley face"
          height="27"
          width="70"
          class="mb-2"
        />
      </div>
      <div id="header-title" class="text-center title">
        <b>ค้นหาสาขาใกล้คุณ</b>
      </div>
    </div>
    <div v-if="showContent">
      <b-row>
        <b-col class="px-0 my-2">
          <div class="penel-select-search px-2">
            <multiselect
              v-model="selected"
              :options="options"
              label="text"
              track-by="value"
              placeholder=""
              selectLabel=""
              selectGroupLabel=""
              selectedLabel=""
              deselectLabel=""
              deselectGroupLabel=""
              :searchable="false"
              @close="
                () => {
                  selected2 = [];
                  getBranch();
                }
              "
            ></multiselect>
            <multiselect
              ref="mySelect"
              class="mt-1"
              v-model="selected2"
              :options="selected.value != 'SKL' ? options2 : []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="เลือกประเภทสินค้า"
              label="name"
              track-by="name"
              :preselect-first="false"
              :searchable="false"
              group-values="children"
              group-label="parent"
              :group-select="true"
              selectLabel=""
              selectGroupLabel=""
              selectedLabel=""
              deselectLabel=""
              deselectGroupLabel=""
              :show-labels="false"
              @close="
                () => {
                  getBranch();
                  isMultiselectOpen = false;
                }
              "
              @open="isMultiselectOpen = true"
            >
              <template slot="tag"><span></span></template>
              <template slot="selection" slot-scope="{ values }">
                <span class="multiselect__single" v-if="values.length == 0">
                  เลือกประเภทสินค้า
                </span>
                <span
                  class="multiselect__single"
                  v-if="values.length && values.length == 1"
                >
                  {{ values[0].name }}
                </span>
                <span
                  class="multiselect__single"
                  v-if="values.length && values.length != 1"
                >
                  {{ values.length }} ประเภทสินค้า
                </span>
              </template>
              <template slot="option" slot-scope="{ option }">
                <template v-if="selected.value != 'SKL'">
                  <template v-if="option.$groupLabel">
                    <span class="pointer-this ml-4">
                      <input
                        class="form-check-input pointer-this"
                        type="checkbox"
                        :checked="
                          selected2.length == options2[0].children.length &&
                            selected2.length > 0
                        "
                        onclick="return false"
                      />
                      <label class="form-check-label pointer-this">
                        {{ option.$groupLabel }}
                      </label>
                    </span>
                  </template>
                  <template v-else>
                    <span class="pointer-this ml-4">
                      <input
                        class="form-check-input pointer-this"
                        type="checkbox"
                        :checked="selected2.includes(option)"
                      />
                      <label class="form-check-label pointer-this">
                        {{ option.name }}
                      </label>
                    </span>
                  </template>
                </template>
                <template v-else>
                  <span></span>
                </template>
              </template>
              <template slot="noOptions">
                <span>
                  ไม่พบข้อมูล
                </span>
              </template>
            </multiselect>
          </div>
          <div></div>
          <div>
            <googleMap
              ref="googleMap"
              :markers="markerList"
              :center="center"
              @to-google-map="
                val => {
                  toGoogleMap(
                    val.LocatorType,
                    val.BranchName,
                    val.BranchMapLatitude,
                    val.BranchMapLongitude
                  );
                }
              "
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="mt-2 mb-0" v-if="selected.value == 'ALL'">
            <b>รายชื่อ สาขาทั้งหมด</b>
          </p>
          <p class="mt-2 mb-0" v-else-if="selected.value == 'SKL'">
            <b>รายชื่อ สำนักงานสาขาสยามคูโบต้า ลีสซิ่ง</b>
          </p>
          <p class="mt-2 mb-0" v-else-if="selected.value == 'AD'">
            <b>รายชื่อ ผู้แทนจำหน่ายสยามคูโบต้า</b>
          </p>
          <p class="mt-2 mb-0" v-else><b>รายชื่อ สาขา</b></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="branchList.length > 0">
          <table class="w-100 tb-branch-list">
            <tr
              class="border-bottom"
              v-for="(list, index) in branchList"
              v-bind:key="index"
              @click="
                onClickBranch(
                  list.BranchMapLatitude,
                  list.BranchMapLongitude,
                  list
                )
              "
            >
              <td class="pin-map">
                <svg
                  width="1.5em"
                  height="2em"
                  viewBox="0 0 16 16"
                  class="bi bi-geo-alt"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  />
                </svg>
              </td>
              <td class="w-65 location-name align-middle">
                {{ list.BranchName }}
              </td>
              <td class="w-15 text-right">
                <img
                  src="@/assets/images/google-maps.png"
                  alt="skl-logo"
                  class="banner-logo"
                  height="100%"
                  @click="
                    toGoogleMap(
                      list.LocatorType,
                      list.BranchName,
                      list.BranchMapLatitude,
                      list.BranchMapLongitude
                    )
                  "
                />
              </td>
            </tr>
          </table>
        </b-col>
        <b-col v-else class="text-center my-3">
          ไม่พบข้อมูล
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import googleMap from "@/components/panels/googleMap";
import Multiselect from "vue-multiselect";

export default {
  name: "BranchList",
  components: {
    googleMap,
    Multiselect
  },
  data() {
    return {
      isMultiselectOpen: false,
      showContent: false,
      type: this.$route.query.type,
      lineProfile: {
        userId: "",
        // userId: "Ubae30bf2f781117833ef37831e65c38a",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      branchFilter: {
        LocatorType: this.$route.query.type ? this.$route.query.type : "ALL",
        Latitude: this.$route.query.lat
          ? parseFloat(this.$route.query.lat)
          : "13.736717", //Bangkok
        Longitude: this.$route.query.long
          ? parseFloat(this.$route.query.long)
          : "100.523186", //Bangkok
        product_list: this.$route.query.product ? this.$route.query.product : ""
      },
      selected: {
        value: "ALL",
        text: "สาขาทั้งหมด"
      },
      options: [
        { value: "ALL", text: "สาขาทั้งหมด" },
        { value: "SKL", text: "สำนักงานสาขาสยามคูโบต้า ลีสซิ่ง" },
        { value: "AD", text: "ผู้แทนจำหน่ายสยามคูโบต้า" }
      ],
      options2: [
        {
          parent: "เลือกทั้งหมด",
          children: []
        }
      ],
      selected2: [],
      center: {
        lat: this.$route.query.lat
          ? parseFloat(this.$route.query.lat)
          : 13.736717, //Bangkok
        lng: this.$route.query.long
          ? parseFloat(this.$route.query.long)
          : 100.523186 //Bangkok
      },
      branchList: [],
      markerList: []
    };
  },
  watch: {
    // selected2() {
    //   this.getBranch(this.branchFilter.LocatorType);
    // }
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_FindBranch })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              // this.getBranch(this.branchFilter.LocatorType);
              this.getProductList();
              this.checkTerm(profile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            // this.getBranch(this.branchFilter.LocatorType);
            this.getProductList();
            this.checkTerm(profile.userId);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    // this.getBranch(this.branchFilter.LocatorType);
  },
  mounted() {},
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getProductList() {
      console.log("getProductList()");
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/location/productList`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          console.log(result);
          if (result.result == 1) {
            var resdata = result.detail;
            this.options2 = [
              {
                parent: "เลือกทั้งหมด",
                children: []
              }
            ];
            if (resdata.length == 0) {
              this.options2 = [];
            } else {
              this.options2 = [
                {
                  parent: "เลือกทั้งหมด",
                  children: resdata
                }
              ];
            }

            console.log(resdata, this.options);
            console.log("this.branchFilter", this.branchFilter);
            var que = [
              ...resdata.filter(x => x.code == this.branchFilter.product_list)
            ];

            console.log(que);
            this.selected2 = que;

            this.selected = this.options.find(
              x => x.value === this.branchFilter.LocatorType
            );
            console.log("getProductList");
            console.log(this.options2);
            console.log("selected2", this.selected2);

            console.log(this.selected);
            console.log(this.branchFilter);

            this.getBranch();
          }
        })
        .catch(error => console.log("error", error));
    },
    getBranch() {
      var locatorType = this.selected.value;
      this.$isLoadingAlpha = true;
      // this.options.forEach(temp => {
      //   if (temp.value == locatorType) {
      //     this.selected = {
      //       value: temp.value,
      //       text: temp.text
      //     };
      //   }
      // });
      console.log("getBranch", this.branchFilter);
      console.log(this.selected2);
      this.branchList = [];
      this.branchFilter.LocatorType = locatorType;

      this.branchFilter.product_list = this.selected2
        .map(a => a.code)
        .join(",");
      console.log(
        "JSON.stringify(this.branchFilter)",
        JSON.stringify(this.branchFilter)
      );
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(this.branchFilter),
        redirect: "follow"
      };
      fetch(`${this.$API_DEV_LINE_SKL}/location/map`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.$isLoadingAlpha = false;
            this.showContent = true;
            this.branchList = result.detail.data;
            this.markerList = [];

            this.markerList.push({
              text: "",
              value: "",
              position: this.center
            });

            result.detail.data.forEach(element => {
              let tempMark = {
                text: element,
                value: "",
                position: {
                  lat: parseFloat(element.BranchMapLatitude),
                  lng: parseFloat(element.BranchMapLongitude)
                }
              };
              this.markerList.push(tempMark);
            });
          } else {
            this.$isLoadingAlpha = false;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    toGoogleMap(type, branchName, lat, lng) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        lineId: this.lineProfile.userId,
        AdOrSKL: type,
        location: branchName,
        ProductType: this.selected2.map(a => a.name).join(",")
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      fetch(`${this.$API_DEV_LINE_SKL}/reportMap`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.$isLoadingAlpha = false;
            window.location =
              "https://www.google.com/maps/search/?api=1&query=" +
              lat +
              "," +
              lng;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    onClickBranch(lat, lng, list) {
      this.center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };
      this.$refs.googleMap.openWindow(this.center, { text: list });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.pin-map {
  color: #ea4335;
}
.tb-branch-list tr .location-name {
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.tb-branch-list tr td {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.penel-select-search {
  position: absolute;
  width: 100%;
  height: 3em;
  z-index: 1;
}

::v-deep .custom-select {
  border-radius: 20px;
  height: 85%;
}

::v-deep .multiselect__option--group {
  background: #fff;
  color: #35495e;
}
::v-deep .multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  -webkit-border-radius: 5px;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
::v-deep .multiselect__option.multiselect__option--selected {
  background: #fff !important;
  color: #35495e;
  font-weight: 400;
}
::v-deep .pointer-this {
  cursor: pointer !important;
}
::v-deep .multiselect-option.selected {
  font-weight: normal !important;
}
::v-deep .multiselect-option.multiselect-option-header {
  font-size: 1em !important;
}
::v-deep .multiselect__option--highlight {
  background: #fff;
  outline: none;
  color: #35495e;
}
::v-deep .multiselect__placeholder {
  display: none;
  width: 0px;
  color: transparent;
}

::v-deep .multiselect__select:before {
  border-color: #000 transparent transparent;
}

.tag-style {
  margin-right: 0.375em;
  margin-bottom: 0.1875em;
  margin-top: 0.1875em;
  display: flex;
  color: #000;
  background-color: #fff;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid green;
  font-size: 75%;
  font-weight: 500;
  padding: 0.25em 0.4em;
}
</style>
