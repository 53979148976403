var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{staticStyle:{"width":"auto","height":"400px"},attrs:{"center":_vm.center,"zoom":8,"map-type-id":"terrain","options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    disableDefaultUi: false
  }}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"icon":index == 0
        ? { url: require('@/assets/images/position_icon.svg') }
        : null,"position":m.position,"clickable":true},on:{"click":function($event){return _vm.openWindow(m.position, m)}}})}),_c('GmapInfoWindow',{attrs:{"opened":_vm.window_open,"position":_vm.infowindow,"options":{
      pixelOffset: {
        width: 0,
        height: -35
      }
    }},on:{"closeclick":function($event){_vm.window_open = false}}},[_c('p',{staticClass:"font-weight-bold mx-0 mt-0 mb-1"},[_c('b',[_vm._v(_vm._s(_vm.data.BranchName))])]),_c('p',{staticClass:"mx-0 mt-0 mb-1"},[_vm._v(_vm._s(_vm.data.BranchAddress))]),_c('p',{staticClass:"mx-0 mt-0 mb-1"},[_vm._v("โทร. "+_vm._s(_vm.data.BranchContactPhone))]),_c('p',{staticClass:"m-0"},[_c('img',{staticClass:"banner-logo mr-1",attrs:{"src":require("@/assets/images/google-maps.png"),"alt":"skl-logo","height":"100%"},on:{"click":function($event){return _vm.$emit('to-google-map', _vm.data)}}}),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('to-google-map', _vm.data)}}},[_c('u',[_vm._v("View on Google map")])])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }