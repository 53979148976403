<template>
  <GmapMap
    :center="center"
    :zoom="8"
    map-type-id="terrain"
    style="width: auto; height: 400px"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false
    }"
  >
    <GmapMarker
      :icon="
        index == 0
          ? { url: require('@/assets/images/position_icon.svg') }
          : null
      "
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      @click="openWindow(m.position, m)"
    />

    <GmapInfoWindow
      @closeclick="window_open = false"
      :opened="window_open"
      :position="infowindow"
      :options="{
        pixelOffset: {
          width: 0,
          height: -35
        }
      }"
    >
      <p class="font-weight-bold mx-0 mt-0 mb-1">
        <b>{{ data.BranchName }}</b>
      </p>
      <p class="mx-0 mt-0 mb-1">{{ data.BranchAddress }}</p>
      <p class="mx-0 mt-0 mb-1">โทร. {{ data.BranchContactPhone }}</p>
      <p class="m-0">
        <img
          src="@/assets/images/google-maps.png"
          alt="skl-logo"
          class="banner-logo mr-1"
          height="100%"
          @click="$emit('to-google-map', data)"
        />
        <a href="#" @click.prevent="$emit('to-google-map', data)">
          <u>View on Google map</u>
        </a>
      </p>
    </GmapInfoWindow>
  </GmapMap>
</template>
<script>
export default {
  props: {
    center: {
      required: true,
      type: Object
    },
    markers: {
      required: true,
      type: Array
    }
  },

  data() {
    return {
      window_open: false,
      infowindow: {},
      data: {}
    };
  },
  async mounted() {},
  methods: {
    openWindow(position, data) {
      console.log(this.window_open);
      this.window_open = false;
      this.data = data.text;
      this.infowindow = position;
      this.window_open = true;
    }
  },
  updated() {}
};
</script>

<style scoped></style>
